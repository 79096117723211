import { useMediaQuery } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import PreloaderView from "../../../hocs/PreloaderView";
import { usePlayAudioSimple } from "../../../hooks/usePlayAudioSimple";
import ModeHeader from "../../../layouts/components/ModeHeader";
import Filler from "../../lessons/components/filler/Filler";
import GameController from "../../lessons/conversationMode/components/game_controller/GameController";
import GameModal from "../../lessons/conversationMode/components/game_modal/GameModal";
import {
  StyledContainer,
  StyledWrapper,
} from "../../lessons/conversationMode/Conversation.styles";
import { LessonView } from "../../lessons/mode3/mode3.styles";
import GameOverView from "../components/GameOverView";
import ScoreBoard from "./boards/ScoreBoard";
import TimerBoard from "./boards/TimerBoard";
import GameSpace from "./components/game_space/GameSpace";
import SidePanel from "./components/side_panel/SidePanel";
import useJsonPreloadParser from "../../../hooks/useJsonPreloadParser";
import { rives } from "../../../shared/riveChar/riveCharacters";

const ConversationGame = (props) => {
  const [data] = useState(props);
  const [questions] = useState(() => data.mode);
  const smallHeader = useMediaQuery("(max-width:600px)");

  const [nextQue, setNextQue] = useState(true);
  const [asking, setAsking] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [currQuestion, setCurrQuestion] = useState(0);
  const [optionSelected, setOptionSelected] = useState(-1);
  const [correct, setCorrect] = useState(false);
  const [answered, setAnswered] = useState(false);
  const [optionTalking, setOptionTalking] = useState(false);

  const [timeLeft, setTimeLeft] = useState(300);
  const [minsInterval, setMinsInterval] = useState(null);
  const [secsInterval, setSecsInterval] = useState(null);

  const [modal, setModal] = useState(false);
  const [timer, setTimer] = useState({ secs: 0, mins: 0 });
  const [score, setScore] = useState(0);
  const [fillerSpeakerTalking, setFillerSpeakerTalking] =
    useState(false);
  const [fillerSpeakerClicked, setFillerSpeakerClicked] =
    useState(false);
  const {
    playArray,
    assignAudio,
    stopAudio,
    setBackgroundMusicUrl,
    setIsBackgroundMusicEnabled,
  } = usePlayAudioSimple(data.audio);
  const [backgroundMusicEnabled, setBackgroundMusicEnabled] =
    useState(false);
  const [preloadImages, preloadAudios] = useJsonPreloadParser(data);

  const [images, setImages] = useState(null);
  const [lessonState, setLessonState] = useState("intro");

  const submitAnswer = () => {
    setAnswered(true);

    stopPlaying();
    if (optionSelected === questions[currQuestion].optionsAnswer) {
      // this is what happens when the correct answer is played
      setCorrect(true);
      playAudio(
        data.optionSounds.correctAnswer,
        "main",
        () => {
          setScore((prevState) => prevState + 5);
        },
        () => {}
      );
    } else {
      setCorrect(false);
      playAudio(
        data.optionSounds.wrongAnswer,
        "main",
        () => {},
        () => {}
      );
    }
    setTimeout(() => {
      if (currQuestion < questions.length - 1) {
        setAnswered(false);
        setCorrect(false);
        setShowOptions(false);
        setOptionSelected(-1);
        setCurrQuestion((prevState) => prevState + 1);
        setNextQue(true);
        setAsking(false);
      } else {
        //   // End Game
        endGame();
      }
    }, 1000);
  };

  useEffect(() => {
    if (answered) {
      console.log("We have tried answering this question");
    }
  }, [answered]);

  function endGame() {
    clearInterval(minsInterval);
    clearInterval(secsInterval);
    setLessonState("gameOver");
    setCurrQuestion(0);
  }

  const selectOption = (option) => {
    stopPlaying();
    playAudio(
      data.optionSounds.select,
      "main",
      () => {
        setOptionSelected(option);
      },
      () => {
        playAudio(
          questions[currQuestion].optionsAudio[option],
          "main",
          () => {
            setOptionTalking(true);
          },
          () => {
            setOptionTalking(false);
          }
        );
      }
    );
  };

  React.useEffect(() => {
    setIsBackgroundMusicEnabled(backgroundMusicEnabled);
  }, [backgroundMusicEnabled]);

  const askQuestion = () => {
    stopPlaying();
    playAudio(
      questions[currQuestion].questionsAudio,
      "main",
      () => {
        setAsking(true);
        setNextQue(false);
        setShowOptions(true);
      },
      () => {
        setAsking(false);
      }
    );
  };

  const restartMode = () => {
    setScore(0);
    setTimer({ secs: 0, mins: 0 });
    setOptionSelected(-1);
    setTimeLeft(300);
    setLessonState("lesson");
    setShowOptions(false);
    setAnswered(false);
    startGame();
  };

  const openModal = (open) => {
    setModal(open);
  };
  const stopPlaying = () => {
    setOptionTalking(false);
    setAsking(false);
    stopAudio();
    // stopTalking();
  };
  React.useEffect(() => {
    if (currQuestion > 0) setTimeout(() => askQuestion(), 500);
  }, [currQuestion]);
  const endHandler = (i) => {
    stopPlaying();
  };

  const playAudio = (
    setAudio,
    speaker,
    playCallback,
    endCallBack
  ) => {
    stopAudio();
    // check if the audio is an array
    if (!Array.isArray(setAudio)) {
      // create a new array and play the array
      const newSetAudio = [];
      newSetAudio.push(setAudio);
      playArray(
        newSetAudio,
        playCallback ? playCallback : alert("play filler"),
        endCallBack ? endCallBack : endHandler,
        0,
        speaker
      );
    } else {
      playArray(
        setAudio,
        playCallback ? playCallback : alert("play filler"),
        endCallBack ? endCallBack : endHandler,
        0,
        speaker
      );
    }
  };
  const fillerSpeakerClickedHandler = (state) => {
    stopPlaying();
    setFillerSpeakerClicked(true);
    playAudio(
      data[state].audio,
      "teacher",
      () => {
        setFillerSpeakerTalking(true);
      },
      () => {
        setFillerSpeakerTalking(false);
      }
    );
  };

  React.useEffect(() => {
    if (timeLeft === 0) endGame();
  }, [timeLeft]);

  function startGame() {
    setLessonState("lesson");
    setMinsInterval(
      setInterval(() => {
        setTimer((prevState) => ({
          ...prevState,
          mins: prevState.mins + 1,
        }));
      }, 1000 * 60)
    );
    setSecsInterval(
      setInterval(() => {
        setTimer((prevState) => ({
          ...prevState,
          secs: prevState.secs < 59 ? prevState.secs + 1 : 0,
        }));
        setTimeLeft((prevState) => prevState - 1);
      }, 1000)
    );
    setTimeout(() => askQuestion(), 1000);
  }

  const fillerNavClickedHandler = (state) => {
    stopPlaying();

    if (state === "intro") {
      startGame();
    }
  };
  const getRivePlaceHolderImages = (chars) =>
    chars ? chars.map((char) => rives[char].placeHolder) : [];

  const handleMusicButtonClicked = React.useCallback(() => {
    setBackgroundMusicEnabled(!backgroundMusicEnabled);
  }, [backgroundMusicEnabled]);
  return (
    <PreloaderView
      images={[
        ...preloadImages,
        ...getRivePlaceHolderImages(data.charIDs),
      ]}
      audios={preloadAudios}
      // speakers={data.speakers}
      onLoad={(a) => {
        //send the loaded audio to the play audio hook
        assignAudio(a);
        // set the background music
        setBackgroundMusicUrl(data.backgroundMusic);
        // set the playing of the background music to true
        setBackgroundMusicEnabled(true);
      }}
      loadImages={(imgs) => setImages(imgs)}
    >
      <LessonView>
        <ModeHeader
          subjectTitle={data.subjectTitle}
          activityName={data.activityName}
          activityTitle={data.activityTitle}
          backgroundColor={data.backgroudColor}
          height={smallHeader ? 60 : 100}
          within={true}
          cursor={data.cursor}
          bgMusic={true}
          musicButtonClicked={handleMusicButtonClicked}
          backgroundMusicEnabled={backgroundMusicEnabled}
          selector={data.selectCursor}
          redirectURL={"/games"}
        />
        {data.intro && lessonState === "intro" && (
          <Filler
            speakerClicked={() =>
              fillerSpeakerClickedHandler("intro")
            }
            forwardClicked={() => fillerNavClickedHandler("intro")}
            payload={{
              ...data.intro,
              cursor: data.cursor,
              pointer: data.selectCursor,
            }}
            talking={fillerSpeakerTalking}
            started={fillerSpeakerClicked}
            navBg={data.intro.navForwardGray}
            navBg1={data.intro.navForwardBg}
            riveSpeaker={data.intro.rive}
            speaker={data.intro.placeHolder}
            navBg2={data.intro.navForwardBlueBg}
            navPointer={data.selectCursor}
            images={images}
          />
        )}

        {(!data.intro || lessonState === "lesson") && (
          <StyledWrapper
            backgroundImage={data.BG}
            cursor={data.cursor}
          >
            <GameModal
              selector={data.selectCursor}
              openModal={openModal}
              open={modal}
              cursor={data.cursor}
              gameLink={data.lessonLink}
              text={"Would you like to go to the lesson mode?"}
            />
            <StyledContainer>
              <div className="boardsContainer">
                <ScoreBoard score={score} />
                <TimerBoard timeLeft={timeLeft} />
              </div>
              <GameController
                onClick={() => {
                  openModal(true);
                }}
                gameBtn={data.lessonIcon}
                selectCursor={data.selectCursor}
              />
              <SidePanel
                optionsText={questions[currQuestion].optionsText}
                smallSpeakers={data.smallSpeakers}
                cursor={data.selectCursor}
                showOptions={showOptions}
                questionText={questions[currQuestion].questionsText}
                selectOption={selectOption}
                optionSelected={optionSelected}
                askQuestion={askQuestion}
                submitAnswer={submitAnswer}
                correct={correct}
                answered={answered}
              />
              <GameSpace
                asking={asking}
                nextQue={nextQue}
                askQuestion={askQuestion}
                cursor={data.cursor}
                selectCursor={data.selectCursor}
                characters={data.speakersPlaceHolder}
                riveChars={data.riveSpeakers}
                charIDs={data.charIDs}
                adultChild={data.adultChild || false}
                questionText={questions[currQuestion].questionsText}
                optionTalking={optionTalking}
                object={questions[currQuestion].object}
                correct={correct}
                answered={answered}
                optionText={
                  optionSelected > -1
                    ? questions[currQuestion].optionsText[
                        optionSelected
                      ]
                    : ""
                }
              />
            </StyledContainer>
          </StyledWrapper>
        )}

        {lessonState === "gameOver" && (
          <GameOverView
            restartHandler={restartMode}
            timeRemaining={timer.mins + " minutes " + timer.secs}
            gamePoints={score}
          />
        )}
      </LessonView>
    </PreloaderView>
  );
};

export default ConversationGame;
