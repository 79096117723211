import styled from "styled-components";
import { centerContent } from "../../../conversationMode/style_mixins";

export const StyledGrid = styled.div`
  border-radius: 10px;
  ${centerContent};
  height: ${(props) => (!props.grid2 ? "180px;" : "150px")};
  width: ${(props) => (!props.grid2 ? "180px" : "160px")};
  background-color: ${(props) =>
    props.selected
      ? !props.grid2
        ? "#b0ffa9"
        : " #D9E021"
      : props.isChecked
      ? "#d8d6d4"
      : "#f2f2f2"};
  overflow: hidden;
  box-shadow: ${(props) =>
    !props.selected ? "0px 5px 5px #cccccc;" : "none"};
  cursor: ${(props) =>
    props.selectCursor
      ? `url(${props.selectCursor}) 16 16, auto`
      : "inherit"};

  @media screen and (max-width: 720px) {
    height: ${(props) => (!props.grid2 ? "160px;" : "150px")};
  }
  .content {
    transition: all 0.2s;
    padding: ${(props) => (props.selected ? "1%" : "10%")};
  }
  span {
    font-size: 30px;
    font-weight: 900;
    text-align: center;
  }
  img {
    object-fit: scale-down;
  }
`;
