import styled from "styled-components";
import {
  absCenter,
  centerContent,
} from "../../../conversationMode/style_mixins";

export const StyledGridContainer = styled.div`
  width: ${(props) => (!props.grid2 ? "600px" : "700px")};
  height: ${(props) => (!props.grid2 ? "420px" : "180px")};
  ${(props) => !props.grid2 && absCenter};
  transform: ${(props) =>
    !props.grid2 ? "translate(-50%, -35%)" : ""};
  z-index: 2;
  ${centerContent};
  flex-wrap: wrap;
  background-color: #ffffff3a;
  border-radius: 8px;
  gap: 10px;
  overflow-y: auto;
  @media screen and (max-width: 720px) {
    width: 95%;
    height: ${(props) =>
      !props.grid2 ? "calc(100vh - 230px)" : "380px"};
    max-width: 460px;
    transform: ${(props) =>
      !props.grid2 ? "translate(-50%, -40%)" : ""};
  }
`;
