import React, { useContext, useState } from "react";
import SpeakerPointer from "../../../shared/SpeakerPointer";
import DisplayGrid from "./components/display-grid/DisplayGrid";
import GridContainer from "./components/grid-container/GridContainer";
import {
  NextSection,
  PrevSection,
} from "./components/section/Section";
import { StyledWrapper } from "./GridLearn.styles";
import { LessonStateContext } from "../../../shared/sharedLayout";

const grids = 6;
function GridLearn({ data }) {
  const [items] = useState(() => data.mode);
  const [currSection, setCurrSection] = useState(1);
  const [currGrids, setCurrGrids] = useState(
    items.slice(currSection - 1, currSection + grids - 1)
  );
  const [selectedGrid, setSelectedGrid] = useState(-1);
  const [playedGrids, setPlayedGrids] = useState([]);
  const [speaking, setSpeaking] = useState(false);
  const { playAudio, stopAudio } = useContext(LessonStateContext);

  const checkGrid = (grid) => playedGrids.indexOf(grid) < 0;

  const selectGrid = (grid) => {
    stopSpeaking();
    setSelectedGrid(grid - 1);
    if (checkGrid(grid)) setPlayedGrids([...playedGrids, grid]);
    playAudio(
      items[grid - 1]?.audio,
      () => {
        setSpeaking(true);
      },
      () => {
        setSpeaking(false);
      }
    );
  };

  const stopSpeaking = () => {
    stopAudio();
    setSpeaking(false);
  };

  React.useEffect(() => {
    setCurrGrids(
      items.slice(currSection - 1, currSection + grids - 1)
    );
  }, [currSection, items]);

  const prevSection = () => {
    stopSpeaking();
    if (currSection === 1) {
      return;
    }
    setSelectedGrid(-1);
    setCurrSection((prevState) => (prevState -= grids));
  };
  const nextSection = () => {
    stopSpeaking();
    if (currSection + grids >= items.length) return;
    setSelectedGrid(-1);
    setCurrSection((prevState) => (prevState += grids));
  };

  return (
    <StyledWrapper
      $cursor={data.cursor}
      $selectCursor={data.selectCursor}
      $bg={data.BG}
    >
      <div className="grid-teacher">
        <SpeakerPointer
          isTalking={speaking}
          charID={"main"}
          size={"small"}
          handleClick={() => {}}
        />
      </div>
      <DisplayGrid
        content={
          items[selectedGrid]?.title || items[selectedGrid]?.text
        }
      />
      <GridContainer
        selectGrid={selectGrid}
        selectCursor={data.selectCursor}
        currGrids={currGrids}
        selected={selectedGrid}
        checkGrid={checkGrid}
      />
      <div className="sectionBtns">
        <PrevSection
          prevSection={prevSection}
          selectCursor={data.selectCursor}
        />
        <NextSection
          nextSection={nextSection}
          selectCursor={data.selectCursor}
        />
      </div>
      <div className="page-num">
        <span>Page {Math.ceil(1 + (currSection - 1) / 6)}</span> of{" "}
        <span>{Math.ceil(items.length / grids)}</span>
      </div>
    </StyledWrapper>
  );
}

export default GridLearn;
