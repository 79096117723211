import styled from "styled-components";
import { centerContent } from "../conversationMode/style_mixins";

export const StyledWrapper = styled.div`
  background: url(${(props) => props.$bg}) no-repeat center center
    fixed;
  background-position: bottom;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  overflow: hidden;
  height: 100vh;
  cursor: ${(props) =>
    props.$cursor ? `url(${props.$cursor}) 16 16, auto` : "inherit"};
  .grid-teacher {
    position: absolute;
    transform: translate(40%, 140%);
    width: 90px;
    height: 90px;
    background-color: #fff;
    border-radius: 50%;
    border: #5f7e5e solid 1px;
    top: 0;
    left: 0;
    div {
      div {
        background-color: transparent;
      }
    }
    @media screen and (max-width: 720px) {
      display: none;
    }
  }
  .sectionBtns {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 100%);
    height: auto;
    width: 740px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    top: 50%;
    @media screen and (max-width: 720px) {
      top: revert;
      bottom: 10px;
      width: 400px;
      transform: translateX(-50%);
    }
  }
  .page-num {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 30px;
    color: #fff;
    font-size: 20px;
    bottom: 0px;
  }
`;

export const StyledWrapper2 = styled(StyledWrapper)`
  ${centerContent};
  flex-direction: column;
  gap: 20px;
  padding-top: 60px;
  .sectionBtns {
    top: revert;
    bottom: 10px;
    width: 400px;
    transform: translateX(-50%);
  }
  @media screen and (max-width: 720px) {
    padding-top: 0px;
  }
`;
