import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams,useParams, useLocation} from 'react-router-dom';
import { userActions } from "../store/user-slice";
import { getUserProfileData } from '../services/user.profile.services';


const ExternalLogin = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    // Get a specific query parameter
    const token = new URLSearchParams(location.search).get('token')//searchParams.get('token');
    const email = new URLSearchParams(location.search).get('email');
    const profilePicture = new URLSearchParams(location.search).get('profilePicture');
    const fullName = new URLSearchParams(location.search).get('fullName');
    
    const dispatch = useDispatch();

    
    const navigate = useNavigate();

    useEffect(()=>{
        let user = {
            token, email, profilePicture, fullName
        }
        
        dispatch(userActions.login({...user }))
        
        async function fetchProfileData() {

            const userData =  await getUserProfileData(user.token);

            if(userData)
            {   
                dispatch(userActions.login({...user,...userData }));                
            }
        }
        
        fetchProfileData();
    
        if(token)
        {           
            if(user.accountType)
            {
                navigate("/select-profile");
            }
            else
            {
                navigate("/welcome");
            }
        }
        else
        {
            navigate('/login');
        }
    },[])

}

export default ExternalLogin;