import React,{ useEffect, useState, useReducer, useCallback } from 'react'
import styled, {keyframes,css} from 'styled-components'; 
import ModeHeader from '../layouts/components/ModeHeader';
import { Howl } from "howler"; 
import GreyLogo from '../assets/images/grey_logo.svg'
import {
    useParams,
    
} from 'react-router-dom';
import Assessment01 from '../modes/assessments/Assessment01';
import Assessment02 from '../modes/assessments/Assessment02';
import Axios from 'axios';
import CustomLoader from '../components/CustomLoader';
import ErrorView from '../components/ErrorView';
import {GET_ASSESSMENT_ACTIVITY_ENDPOINT, assessmentsDataBaseURL} from '../constants/URLs'

const GameNotFoundWrapper = styled.div`
    padding-top: 60px;
    /* height: calc(100vh - 60px); */
    width: 100%;
    height:100%;
    background: #231705;    
    background: #ffcf94;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-content: center; */
    align-items: center;

`;

/* To be loaded from the Backend API */
/* Contains the game info, matched with the gameCode */
const gamesInfo = [
    {
        gameTitle:"Greetings Game 01",
        gameCode: "greetings-01",
        gameLessonName:"Lesson 02",
        subjectTitle:"English"
    },
    {
        gameTitle:"Letter Matching Game 01",
        gameCode: "letter-matching-game-01",
        gameLessonName:"Lesson 02",
        subjectTitle:"English"
    },
    {
        gameTitle:"Alphabet Identification",
        gameCode: "alphabet-identification-01",
        gameLessonName:"Lesson 01",
        subjectTitle:"English"
    },
    {
        gameTitle:"Letter Identification",
        gameCode: "letter-identification-01",
        gameLessonName:"Lesson 01",
        subjectTitle:"English"
    },
    {
        gameTitle:"Number Identification",
        gameCode: "number-identification-01",
        gameLessonName:"Lesson 01",
        subjectTitle:"Mathematics"
    },
    {
        gameTitle:"Number Counting",
        gameCode: "number-counting-01",
        gameLessonName:"Lesson 02",
        subjectTitle:"Mathematics"
    },
    {
        gameTitle:"Number Identification 2",
        gameCode: "number-identification-game-02",
        gameLessonName:"Lesson 03",
        subjectTitle:"Mathematics"
    },
    {
        gameTitle:"Missing Letter Identification",
        gameCode: "missing-letter-identification-game-01",
        gameLessonName:"Lesson 01",
        subjectTitle:"English"
    },
    {
        gameTitle:"Missing Letter Identification 2",
        gameCode: "missing-letter-identification-game-02",
        gameLessonName:"Lesson 01",
        subjectTitle:"English"
    },
    {
        gameTitle:"Food Identification",
        gameCode: "food-identification-game-01",
        gameLessonName:"Lesson 02",
        subjectTitle:"English"
    },
    {
        gameTitle:"Identifying Things in the Home",
        gameCode: "identifying-things-in-the-home-game-01",
        gameLessonName:"Lesson 02",
        subjectTitle:"English"
    },
    {
        gameTitle:"Identifying Animals",
        gameCode: "animal-identification-game-01",
        gameLessonName:"Lesson 03",
        subjectTitle:"English"
    }
]

const AssessmentSelector = (props) => {
   
    console.log("Selector", props)
    switch(props.activityMode)
    {
        case 'assessment01':
            return <Assessment01 isBackgroundMusicMuted={props.isBackgroundMusicMuted} {...props}/>
        case 'assessment02':
            return <Assessment02 isBackgroundMusicMuted={props.isBackgroundMusicMuted} {...props}/> 
         
        default:
            return <GameNotFoundWrapper><img style={{width:200, opacity:0.4}} src={GreyLogo}/><div style={{fontSize:32, color:'#c16c35', padding:20}}>Assessment not found!</div></GameNotFoundWrapper>
    }

}

const AssessmentPage = () => {

    const {assessmentCode} = useParams();

    let headerInfo = gamesInfo.find( gi => gi.gameCode === assessmentCode);

    // declare the state for setting the data
    const [data, setData] = useState(null);
    const [isError, setIsError] = useState(false); 
    
    // Load the game data from the back-end using the game code.
    useEffect(() => {
        async function loadData() {
        await Axios.get(`${GET_ASSESSMENT_ACTIVITY_ENDPOINT}&code=${assessmentCode}`, {headers:{"Access-Control-Allow-Origin": "*"}})
            .then((response) => { 
                setData({...response.data.jsonData, code: assessmentCode}); 
            })
            .catch((error) => {
                setIsError(true)
            setData(null); 
            });
        }

        loadData();

    }, []);  

    if(!headerInfo)
    {
        headerInfo = {
            subjectTitle: "",
            gameLessonName:data?.title? data.title : "-",
            gameTitle:data?.title? data.title : "-",
        }
    }

    console.log(headerInfo, data)

    const [isBackgroundMusicMuted, setIsBackgroundMusicMuted] = useState(true);

    const handleBackgroundMusicMute = () =>
    {
        setIsBackgroundMusicMuted(bc => !bc)
    }

    //clicked={musicButtonClicked} enabled={backgroundMusicEnabled}

    return (<div style={{height:'100vh', display:'flex', flexDirection:'column'}}>
        <ModeHeader bgMusic={true} musicButtonClicked={handleBackgroundMusicMute} backgroundMusicEnabled={isBackgroundMusicMuted} within={true} backgroundColor={'#c16c35'} textColor={"#FFFFFF"} subjectTitle={headerInfo.subjectTitle}  activityName={headerInfo.gameLessonName} activityTitle={headerInfo.gameTitle} />       
        
        {isError ? (
            <ErrorView message={"An error has occured while loading your data..."} />
        ) : data  ? (
            <AssessmentSelector isBackgroundMusicMuted={isBackgroundMusicMuted} {...data}/>    
        ) : (
            <CustomLoader />
        )}
        </div>)
}

export default AssessmentPage;