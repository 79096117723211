import React, { useState, useEffect } from "react";
import withNavigation from "../../hocs/withNavigation";
import "../page.css";
import { Link, useNavigate } from "react-router-dom";

import {useImagesContext} from '../../contexts/ImagesContext';
import Axios from 'axios';
import CustomLoader from '../../components/CustomLoader';
import ErrorView from '../../components/ErrorView';
import {GET_CLASS_LIST_ENDPOINT, GET_SUBJECT_LIST_ENDPOINT, appDataBaseURL} from '../../constants/URLs'
import dummyThumbnail from '../thumbnails/greetings-01.JPG';
import activitiesFilterBarBackground from '../../assets/images/ui-images/top menu bar-01.svg';
import { useSelector } from 'react-redux';
import inputBackground from "../../assets/images/ui-images/Menu button-01.svg";
import DropdownInput from "../../components/DropdownInput/DropdownInput";
import PreloaderView from "../../hocs/PreloaderView";
import NonInteractivePreloader from "../../hocs/NonInteractivePreloader";

const filterLessons = (
  lessonsList,
  searchString,
  selectedClass,
  selectedSubject
) => {
  if (lessonsList) {
    if (selectedClass && selectedSubject)
      return lessonsList.filter(
        (l) =>
          l.subjectKey === selectedSubject &&
          l.class === selectedClass &&
          l.title?.toLowerCase()?.match(searchString?.toLowerCase())
      );
    else if (selectedSubject)
      return lessonsList.filter(
        (l) =>
          l.subjectKey === selectedSubject &&
          l.title?.toLowerCase()?.match(searchString?.toLowerCase())
      );
    else if (selectedClass)
      return lessonsList.filter(
        (l) =>
          l.class === selectedClass &&
          l.title?.toLowerCase()?.match(searchString?.toLowerCase())
      );
    else
      return lessonsList.filter((l) =>
        l.title?.toLowerCase()?.match(searchString?.toLowerCase())
      );
  } else {
    return [];
  }
};

const ActivitiesBrowser = (props) =>
{
    /* Load the defined school subjects from the backend */
    const [schoolClasses, setSchoolClasses] = useState([]);
    const [schoolSubjects, setSchoolSubjects] = useState([]);
    const [isError, setIsError] = useState(false);     
    const currentUserProfile = useSelector(state => state.user.currentUserProfile);    
    const [searchString, setSearchString] = useState("");
    const [selectedClass, setSelectedClass] = useState("");
    const [selectedSubject, setSelectedSubject] = useState("");
    const { images } = useImagesContext();  

    const navigate = useNavigate();

    // Load the classes list from the back-end.
    useEffect(() => {
        async function loadData() {
        await Axios.get(`${GET_CLASS_LIST_ENDPOINT}`, {headers:{"Access-Control-Allow-Origin": "*"}})
            .then((response) => { 
                setSchoolClasses(response.data ? [...response.data] : []); 
            })
            .catch((error) => {
                setIsError(true)
                setSchoolClasses([]); 
            });
        }

        loadData();

    }, []);

    useEffect(()=>{

      if(!currentUserProfile)// || currentUserProfile.accountType)
      {
        navigate('/manage-profiles');
      }

      console.log(" Logging current user",currentUserProfile)

    },[currentUserProfile])

    
    // Load the subjects list from the back-end.
    useEffect(() => {
        async function loadData() {
        await Axios.get(`${GET_SUBJECT_LIST_ENDPOINT}`, {headers:{"Access-Control-Allow-Origin": "*"}})
            .then((response) => { 
                setSchoolSubjects(response.data ? [...response.data] : []); 
            })
            .catch((error) => {
                setIsError(true)
                setSchoolSubjects([]); 
            });
        }

        loadData();

    }, []);  

    useEffect(()=>{
        console.log("Items List", props.activityItems)
    }, [props.activityItems])

    const handleClassSelectionChange = (value) =>
    {
        setSelectedClass(value)
    }

  const handleSearchStringChange = (e) => {
    setTimeout(() => setSearchString(e.target.value), 2000);
  };

  const handleSubjectSelectionChange = (value) => {
    setSelectedSubject(value);
  };

  const checkIsNew = (dateTimeCreated) => {
    if (dateTimeCreated) {
      let dateValue = new Date(dateTimeCreated);
      var date2 = new Date();
      var diff = new Date(date2.getTime() - dateValue.getTime());

      if (diff.getUTCMonth() < 3) return true;
    }

    return false;
  };

  const goToURL = (url) => {
    //navigate(redirectURL || '/lessons');
    if (url) {
      navigate(url);
    }
  };

  return (
    <NonInteractivePreloader
      background={"none"}
      images={props.preloadedImages}
      audios={props.preloadedAudios}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            height: 45,
            fontSize: 30,
            padding: 10,
            fontFamily: "chewy",
            color: "#8f3735",
            background: `url('${activitiesFilterBarBackground}'}`,
          }}
        >
          {props.activityTitle || "Activity"}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            //background:`url('${activitiesFilterBarBackground}')`,
            //backgroundSize:'cover',
            //overflowX:'auto',
            width: "100%",
            //padding:20,
            borderBottom: "0px solid #CCCCCC",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              background: `url('${activitiesFilterBarBackground}')`,
              backgroundSize: "cover",
              //overflowX:'auto',
              width: "100%",
              padding: 20,
              borderBottom: "0px solid #CCCCCC",
            }}
          >
            <div>
              <input
                onChange={handleSearchStringChange}
                type={"search"}
                placeholder={"Search..."}
                style={{
                  padding: "15px 20px",
                  outline: "none",
                  fontSize: 14,
                  fontWeight: "bold",
                  fontFamily: "chewy",
                  minWidth: 150,
                  width: "90%",
                  boxSizing: "border-box",
                  position: "relative",
                  margin: 8,
                  display: "flex",
                  flexDirection: "column",
                  border: "3px solid #ce730f",
                  justifyContent: "center",
                  borderRadius: 20,
                  height: 40 /* alignItems:'center', */,
                  background: `#f5cc60`,
                }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <DropdownInput
                onSelectedHandler={handleClassSelectionChange}
                defaultValue={schoolClasses[0]?.name}
                options={schoolClasses?.map((vo) => ({
                  value: vo.key,
                  caption: vo.name,
                }))}
              />
            </div>
            {/* <div><label>Select Class</label>
            <select onChange={handleClassSelectionChange} style={{
                padding:10,
                fontSize:14,
                fontWeight:'bold',
                color:'#0099ff',
                width:'90%',
                margin:5,
                boxSizing:'border-box',
                border:'1px solid #0f75bc', 
                borderRadius:5,
                backgroundColor:'#eaf9ff',
            }} defaultValue={schoolClasses[0]?.name}>
                {schoolClasses.map(sc => <option value={sc.key}>
                        {sc.name}
                </option>)}
            </select>
        </div> */}
            <div style={{ display: "flex" }}>
              <DropdownInput
                onSelectedHandler={handleSubjectSelectionChange}
                defaultValue={schoolSubjects[0]?.name}
                options={schoolSubjects?.map((vo) => ({
                  value: vo.key,
                  caption: vo.name,
                }))}
              />
            </div>
            {/* <div><label>Select Subject</label>
            <select onChange={handleSubjectSelectionChange}  style={{
                padding:10,
                fontSize:14,
                fontWeight:'bold',
                color:'#0099ff',
                width:'90%',
                margin:5,
                boxSizing:'border-box',
                border:'1px solid #0f75bc', 
                borderRadius:5,
                backgroundColor:'#eaf9ff',
            }} defaultValue={schoolSubjects[0]?.name}>
                {schoolSubjects.map(sc => <option value={sc.key}>
                        {sc.name}
                </option>)}
            </select>
        </div> */}
          </div>
        </div>

        {/*<div style={{margin:10, display:'flex', flexDirection:'column', width:'90%',justifyContent:'left'}}>*/}
        <div style={{ width:'100%',display: 'inline-block'}}>
        {
            schoolSubjects.filter(scs => scs.key !== "" && (selectedSubject === "" || selectedSubject !== "" && scs.key === selectedSubject)).map(ss => <div key={ss.code} style={{padding:20}} >
                <div className={'side-menu-category-label'} style={{fontSize:26, fontFamily:'chewy', color:'#333333', fontWeight:'normal', borderBottom:'1px dotted'}}>{ss.name}</div>
                <div style={{ width:'100%',display: 'inline-grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))', minHeight:'calc(100vh-50px)'}}>
                {filterLessons(props.activityItems, searchString, selectedClass, selectedSubject).filter(s => s.subjectKey === ss.key).map(sm => 
                <Link key={sm.id} style={{textAlign:'center', textDecoration:'none', margin:10, maxWidth:'95%'}} to={sm.menuLink || sm.code}>
                <div style={{display:'flex', padding:10, background:'#813a00', boxShadow:'2px 2px 10px 1px rgba(10,10,10,0.5)', borderRadius:25}}>
                    <div className={'activity-card-wrapper'}>
                    {checkIsNew(sm.dateTimeCreated) && <div className={'new-activity-marker'}>New</div>}
                    <div style={{width:'100%', height:260, borderRadius:20, backgroundColor:'#eeeeee', backgroundSize:'cover', backgroundImage: sm.thumbnailLink ? `url('${sm.thumbnailLink}')` : `url('${dummyThumbnail}')` }}></div>
                    <div /* className={'activity-card-info'} */>
                        <div style={{textAlign:'center', textDecoration:'none', maxWidth:'95%'}} to={sm.menuLink || sm.code}>
                            <div style={{color:"white", fontFamily:'chewy', fontSize:18, padding:'20px', whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden', maxWidth:'100%'}}>{sm.title? sm.title : sm.caption}</div>
                            {/*<div style={{color:'#0099ff', fontSize:13, fontWeight:'bold'}} >{sm.caption}</div>*/}
                        </div>                        
                    </div>
                </div> 
                </div></Link>)} 
                
                           
                             
                            
                   
                </div>
              </div>
            )}
        </div>
      </div>
    </NonInteractivePreloader>
  );
};

export default ActivitiesBrowser;
