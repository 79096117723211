export const rives = {
  main: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/teacher-head.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/teacher/t2.svg",
  },
  john: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/john.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/boyFlipped/boyFlippedStable1.svg",
  },
  tobi: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/tobi.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Tobi/Tobi-05.svg",
  },
  fullTeacher: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/teacher.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/fullTeacher/t6.svg",
  },
  mandy: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/mandy.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Mandy/MandyStable1.svg",
  },
  nazo: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/nazo.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/speakers/Nazo/NazoStable1.svg",
  },
  binta: { rive: "", placeHolder: "" },
  adamu: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/adamu.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Adamu.svg",
  },
  musa: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/musa.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Musa.svg",
  },
  chioma: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/chioma.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Chioma.svg",
  },
  ada: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/ada.riv",
    placeHolder:
      "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Ada.svg",
  },
  mum: { rive: "", placeHolder: "" },
  chiomaMum: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/chioma's_mum.riv",
    placeHolder: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Chioma_mum.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Chioma_mum-Head.svg",
  },
  chiomaChild: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/chioma_child.riv",
    placeHolder: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Chioma_child.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Chioma_child-Head.svg",
  },
  dele: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/dele.riv",
    placeHolder: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Dele.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Dele-Head.svg",
  },
  bobby: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/bobby.riv",
    placeHolder: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Bobby.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Bobby-Head.svg",
  },
  bobbyDad: {
    rive: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/rive/bobby's_dad.riv",
    placeHolder: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Bobby_dad.svg",
    head: "https://biamuta-activities-assets.s3.us-east-2.amazonaws.com/general/svgs/Bobby_dad-Head.svg",
  },
};
