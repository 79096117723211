import React, { useEffect, useState } from "react";
import useImagePreloader from "../hooks/useImagePreloader";
import useAudioPreloader from "../hooks/useAudioPreloader";
import Loader from "react-loader-spinner";
import useSpeakerPreloader from "../hooks/useSpeakerPreloader";
import { useTeacher } from "../hooks/useTeacher";
import LoadingPage from "../components/LoadingPage/LoadingPage";

const PreloaderView = (props) => {
  // Callback to return a list of loaded Howls
  const [audioIsReady, audiosReadyCnt, audios] = useAudioPreloader(
    props.audios
  );

  // Callback to return a list of loaded images
  const [imageIsReady, imagesReadyCnt, pageImages] =
    useImagePreloader(props.images);

    //  Callback to load the speakers
  const [speakersPreloaded, speakersReadyCount] = useSpeakerPreloader(
    props.speakers
  );

  const [totalResourcesCount, setTotalResourcesCount] =
    useState(null);

  const { getSpeakerImageArray } = useTeacher();

  useEffect(() => {
    const totalImageLength = props.images ? props.images.length : 0;
    const totalAudioLength = props.audios ? props.audios.length : 0;
    const totalSpeakersLength = props.speakers
      ? props.speakers.reduce((prev, current) => {
          return prev + getSpeakerImageArray(current).length;
        }, 0)
      : 0;
    const ttl =
      totalImageLength + totalAudioLength + totalSpeakersLength;
    setTotalResourcesCount(ttl);
  }, []);
  /*useEffect(()=>{
        props.setAudios && props.setAudios(audios)
    },[audioIsReady])*/

  useEffect(() => {
    // console.log("preloader", {audioIsReady, imageIsReady, speakersPreloaded})
    // if(audioIsReady)
    // {
    //     props.onLoad && props.onLoad(audios)
    // }

    if (audioIsReady && imageIsReady && speakersPreloaded) {
      props.onLoad && props.onLoad(audios);
      props.loadImages && props.loadImages(pageImages);
    }
  }, [audioIsReady, imageIsReady, speakersPreloaded]);

  return (imageIsReady &&
    audioIsReady &&
    (props.speakers ? speakersPreloaded : true)) ||
    totalResourcesCount === 0 ? (
    props.children
  ) : (
    <>
      <div
        style={{
          minHeight: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          position: "relative",
          backgroundColor: props.background
            ? props.background
            : "white",
        }}
      >
        <LoadingPage
          percentLoaded={Math.ceil(
            ((imagesReadyCnt + audiosReadyCnt + speakersReadyCount) /
              totalResourcesCount) *
              100
          )}
        />
        {/*<div style={{fontSize:14, fontWeight:'bold', color:'#444444'}}>
        Loading game data...{`${Math.ceil((imagesReadyCnt+audiosReadyCnt)/totalResourcesCount *100)}%`}
        </div>*/}
        {/*  <div style={{justifyContent:'center', display:'flex', flexDirection:'column'}}><Loader type="ThreeDots" color="#ff9900" height="50" width="100" /></div>

        <span style={{fontSize:20, color:'#ff9900'}}>{`${Math.ceil(((imagesReadyCnt+audiosReadyCnt+speakersReadyCount)/totalResourcesCount) *100)}%`}</span>
       
        <div style={{height:10, width:150, position:'relative', border:'1px solid #ff9900', margin:10, borderRadius:5, backgroundColor:'#ffffff'}}>
            {totalResourcesCount && <div style={{height:8,width:`${Math.ceil(((imagesReadyCnt + audiosReadyCnt + speakersReadyCount)/totalResourcesCount) *100)}%`, borderRadius:5, backgroundColor:'#ff9900'}}></div>}
        </div> */}
      </div>
    </>
  );
};

export default PreloaderView;
