import {createSlice} from "@reduxjs/toolkit";

let user = JSON.parse(localStorage.getItem('currentUser'));
let currentUserProfile = JSON.parse(localStorage.getItem('currentUserProfile'));

const initialState = {
    user:user ? {...user}:null,
    currentUserProfile: currentUserProfile ? {...currentUserProfile} : user,
    userProfiles:[],
    userProfilePhotos:{}
}

const emptyUserDetails= {fullName: '',  phoneNumber:'',  email: '', profilePicture: '', location:'', nationality:''}


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login(state,action) {

            state.user = {...emptyUserDetails, ...state.user, ...action.payload};
            localStorage.setItem('currentUser', JSON.stringify({...state.user}));
            console.log('current state after login is', state.user);
        },
        setActiveProfile(state,action) {

            state.currentUserProfile = action.payload; //state.currentUserProfile ? {...state.currentUserProfile,
               // ...action.payload } : action.payload;

            localStorage.setItem('currentUserProfile', action.payload ? JSON.stringify(action.payload) :action.payload )
            console.log('current state after currentUserProfile is', state.user)
        },
        setUserProfiles(state,action) {

            state.userProfiles = [...action.payload];

            //localStorage.setItem('currentUserProfile', action.payload ? JSON.stringify(action.payload) :action.payload )
            console.log('current state after currentUserProfile is', state.user)
        },
        setUserProfilePhotos(state, action){
            state.userProfilePhotos = {...state.userProfilePhotos,[action.payload.id]:action.payload.data}
        },
        logout(state){
            state.user = null;
            state.currentUserProfile = null;
            console.log('current state after currentUserProfile is', state.user)
        }
    }

})

export const userActions = userSlice.actions

export default userSlice.reducer